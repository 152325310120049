body{
    background:#FFFFFF;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-size:16px;
    font-weight:400;
    color:#5a5f69;
    line-height: 26px;
}

.clear{
    clear:both;
}

img {
    margin:0;
}


.img-responsive_full{
    display: block;
    width: 100%;
    height: auto;
}

.owl-theme .owl-nav [class*=owl-],
.owl-theme .owl-nav [class*=owl-]:hover {
    background: none;
    height: 0;
}
.owl-theme .owl-nav {
    margin-top: 0px;
    text-align: center;
    height: 0px;
}

b,strong{
    font-weight:700;
}

h1{
    font-family: 'Pathway Gothic One', sans-serif;
    color:#000000;
    font-size:48px;
    font-weight:400;
    margin-bottom: 20px;
    text-transform: uppercase;
}

h2{
    font-family: 'Pathway Gothic One', sans-serif;
    color:#000000;
    font-size:38px;
    font-weight:400;
    text-transform: uppercase;
}


h3{
    font-family: 'Pathway Gothic One', sans-serif;
    color:#000000;
    font-size:28px;
    font-weight:400;
    text-transform: uppercase;
}

h4{
    font-family: 'Pathway Gothic One', sans-serif;
    color: #ff4000;
    font-size: 32px;
    text-transform: uppercase;
}


a {
    color:#fd3f00;
    border:none;
}

a:hover,
.active a{
    color:#fd3f00;
    text-decoration: underline;
}


.row_margin{
    margin-right:0;
    margin-left:0;
}

.pad{
    padding-left:0;
    padding-right:0;
}

.center-block{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.bold{
    font-weight: 700;
}

.header{
    background-color: #00b9e9;
    padding: 8px 0;
    position: relative;
}

.dove_header{
    color: #fff;
    float: left;
    margin-top: 4px;
}

.dove_header img{
    padding-right: 5px;
}

.tel_header{
    margin-left: 25px;
}

.tel_header a{
    color: #fff;
    text-decoration: none;
}

.select_bandiera{
    width: 70px;
    float: left;
    margin-left: 25px;
}

.form_bandiera{
    background: transparent;
    border: 2px solid #fff;
    border-radius: 0;
    color:#fff;
}

.form_bandiera button,
.form_bandiera button:hover,
.form_bandiera button:active
{
    background: transparent;
    border: 2px solid #fff;
    color: #fff !important;
}

.logo_residence{
    position: absolute;
    display: block;
    z-index: 300;
}

.nav>li>a{
    padding: 14px 12px;
}

.navbar-default{
    background-color: #fff;
    border: none;
}

.navbar-default .navbar-nav>li>a{
    color:#5a5f69;
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a.active{
    color: #fd3f00;
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover{
    background-color: #f9f9f9;
    color: #fd3f00;
}

.dropdown-menu{
    border: 6px solid #f3f4f6;
    border-radius: 0;
    -webkit-box-shadow:none;
            box-shadow:none;
    padding: 0;
}

.dropdown-menu>li>a{
    padding: 7px 20px;
    color: #5a5f69;
    border-bottom: 1px solid #f5f5f5;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a.active{
    color: #fd5900;
    background-color:transparent;
}

.pulsante_arancio{
    text-transform: uppercase;
    color: #fff !important;
    text-decoration: none !important;
    font-weight: bold;
    background-color: #fd3f00;
    border: none;
    border-radius: 0;
    font-size: 18px;
    padding: 7px 27px;
}

.pulsante_arancio:hover,
.pulsante_arancio.active{
    background-color: #fd5900;
    color: #fff;
}

.spazio_pulsante_prenota_menu{
    margin-top: 5px;
}

.navbar{
    margin-bottom: 0;
}

.spazio_menu{
    margin-top: 23px;
}

.logo_mobile{
    width: 40%;
}

.banner_top{
    margin-top: 24px;
    position: relative;
}

.spazio_prenota{
    background-color: #fd3f00;
    padding: 20px 0;
    position: absolute;
    display: block;
    z-index: 300;
    bottom: -85px;
}

.voci_prenota{
    color: #fff;
}

.pulsante_bianco{
    border: 3px solid #fff;
    border-radius: 0;
    color:#fff;
    background-color: transparent;
    font-size: 18px;
    font-weight: 700;
    padding: 17px 34px;
}

.pulsante_bianco:hover,
.pulsante_bianco.active{
    border: 3px solid #fff;
    border-radius: 0;
    color:#fff;
    background-color: #fd5900;
}

.prenota_absolute{
    position: relative;
}

.arancio{
    color: #fd3f00;
}

.spazio_intro_home{
    margin-top: 80px;
    margin-bottom: 50px;
}

.pulsante_azzurro{
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    background-color: #00b9e9;
    border: none;
    border-radius: 0;
    font-size: 18px;
    padding: 7px 27px;
    margin: 20px 0;
}

.pulsante_azzurro:hover,
.pulsante_azzurro.active{
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    background-color: #1bd0ff;
    border: none;
}

.titolo_news_hp{
    font-size: 27px;
    color:  #00b9e9 !important;
    text-transform: uppercase;
    font-family: 'Pathway Gothic One', sans-serif;
    text-decoration: none !important;
}

.riga_news{
    border-left: 2px solid #00b9e9;
    margin-left: 2px;
}

.mappa_isolino{
    background: url(../images/mappa.png) center no-repeat;
    background-size: cover;
    display: block;
}
#map {
    height: 400px;
}

.container_news_hp{
    padding-top: 30px;
    position: relative;
}

.carousel-indicators li{
    border: none;
    background-color: #cfd1d3;
}

.carousel-indicators .active{
    background-color: #5a5f69;
}

.carousel-indicators{
    right: 0;
    left: auto;
    width: auto;
    margin-left: 0;
    text-align: right;
    top: 0;
}

.bg_azzurro{
    background-color: #00b9e9;
    padding: 20px 0;
    margin-top: 30px;
}

.servizio_top h2{
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 27px;
}

.servizio_top p{
    font-size: 14px;
    color: #fff;
    text-align: center;
    margin-bottom: 0;
}

.banner_appartamenti{
    position: relative;
}

.appartamenti_hp{
    position: absolute;
    z-index: 400;
    display: block;
    background-color: #fff;
    width: 53%;
    top:10%;
    right: 12%;
}

.prezzo_app_hp{
    background-color: #00b9e9;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    padding: 9% 0px;
}

.testo_app_hp{
    padding: 5% 30px;
}

.costo_hpp{
    font-size: 48px;
    font-weight: 400;
    margin: 20px 0;
}

.onde{
    position: absolute;
    display: block;
    z-index: 300;
    bottom: -13px;
}

.onde_top{
    position: absolute;
    display: block;
    z-index: 300;
    top: -13px;
}

.center{
    text-align: center;
}

.text_guarda{
    font-family: 'Pathway Gothic One', sans-serif;
    color: #000000;
    font-size: 32px;
    margin: 17px 0;
}

.info_guarda{
    font-size: 14px;
    line-height: 20px;
    margin: 17px 0;
}

.lista_guarda{
    margin-top: 50px;
    margin-bottom: 50px;
}

.azzurro_hp{
    background-color: #00b9e9;
}

.spazio_info_gite{
    padding: 25px 15px;
    color: #fff;
    font-size: 18px;
}

.spazio_info_gite p{
    margin-bottom: 20px;
}

.spazio_gite_hp{
    margin: 70px 0 50px 0;
}

.titolo_accesso{
    text-align: center;
    margin-bottom: 50px;
}

.img_accesso{
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
    position: relative;
}

.info_accesso{
    position: absolute;;
    display: block;
    z-index: 100;
    bottom: 0;
    text-align: center;
    background-color: #fff;
    padding: 30px 20px;
    left: 50%;
    margin-left: -120px;
}
.info_accesso_d{
    position: absolute;;
    display: block;
    z-index: 100;
    bottom: 0;
    text-align: center;
    background-color: #fff;
    padding: 30px 20px;
    left: 50%;
    width: 175px !important;
    padding-top: 15px !important;
    margin-left: -87.5px !important;
}
.info_accesso_d .pulsante_arancio {
    font-size: 0.8em;
    padding: 5px 8px;
}

.logo_accesso{
    margin-bottom: 15px;
}

.relative{
    position: relative;
}

.info_lago{
    position: absolute;;
    display: block;;
    z-index: 200;
    right: 10%;
    bottom: -110px;
}

.dati_isolino{
    line-height: 35px;
}

.logo_small{
    width: 70%;
    margin-top: 22px;
}

.spazio_info_isolino{
    margin-top: 30px;
    margin-bottom: 30px;
}

.separatore_footer{
    border-top: 1px solid #d6d6d6;
}

.footer_uno{
    margin:30px 0;
}

.titolo_link{
    font-family: 'Pathway Gothic One', sans-serif;
    color: #00b9e9;
    font-size: 27px;
}

.lista_link{
    padding-left: 0;

}

.lista_link li{
    list-style-type: none;
}

.lista_link li a{
    text-decoration: none;
    color: #5a5f69;
}
.lista_link li a:hover,
.lista_link li a:active{
    text-decoration: underline;
    color: #5a5f69;
}

.img_logo_footer{
    margin-bottom: 30px;
}

.prenota_footer{
    margin-top: 10%;
}

.footer_finale{
    background-color:#5a5f69;
    padding: 10px 0;
}

.finale{
    font-size: 14px;
}

.finale ul{
    padding-left: 0;
}

.finale li{
    list-style-type: none;
    float: left;
    padding-right: 20px;
    color: #fff;
}

.finale a{
    color: #fff;
    text-decoration: underline;
}

.breadcrumb{
    background-color: transparent;
    font-size: 13px;
}

.breadcrumb a{
    color: #34373a;
}

.breadcrumb>.active{
    color: #a0a0a0;
}

.breadcrumb {
    padding: 30px 0px 0 0;
    margin-bottom: 0px;
    list-style: none;
    border-radius: 4px;
}

.contenuti_interne{
    margin: 20px 0 30px 0;
}

h4 a:hover{
    text-decoration: none;
}

.lista_due{
    margin-bottom: 30px;
}

.intro_app{
    margin: 25px 0 50px 0;
}

.animali_no{
    margin-bottom: 20px;
}

.tr_top td{
    background-color: #00b9e9;
    color: #fff; 
}

.spazio_tabella{
    text-align: center;
    margin: 30px 0;
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th{
    padding: 20px 8px;
}

.bg_azz_tab{
    background-color: #00b9e917;
    width: 230px;
}

.table-striped>tbody>tr:nth-of-type(odd){
    background-color: #f3f4f6;
}

.titolo_tabella{
    font-family: 'Pathway Gothic One', sans-serif;
    font-size: 32px;
}

.margin_top{
    margin-top:25px;
}

.margin_bottom{
    margin-bottom:25px;
}

.box_info_azzurro{
    border: 2px solid #00b9e9;
    padding: 15px;
    margin-bottom: 15px;
}

.titolo_box{
    font-family: 'Pathway Gothic One', sans-serif;
    font-size: 32px;
    color: #000;
}

.box_info_arancio{
    border: 2px solid #ff4000;
    padding: 15px;
    margin-bottom: 15px;
}

.box_info_verde{
    border: 2px solid #8cc641;
    padding: 15px;
    margin-bottom: 15px;
}

.info_vantaggi{
    margin-left: 0;
    left: 60%;
}

.logo_vantaggio{
    width: 40%;
}

.img_gallery{
    border: 2px solid #fff;
}

.spazio_gallery{
    margin: 30px 0;
}


/* GRANDE SCHERMO */
@media (min-width: 1288px){
    

}

@media (min-width: 1200px)
{
.container {
    width: 1260px;
}
}

/* GRANDE SCHERMO */
@media (max-width: 1489px){
    


}


/* GRANDE SCHERMO */
@media (max-width: 1288px){
    

}

/* MEDIO */
@media (max-width: 1200px){

.pulsante_bianco{
    border: 3px solid #fff;
    border-radius: 0;
    color:#fff;
    background-color: transparent;
    font-size: 16px;
    font-weight: 700;
    padding: 17px 24px;
}

.testo_app_hp h2{
    font-size: 24px;
}

.testo_virtual{
    font-size: 11px;
}

.titolo_app{
    min-height: 70px;
}

    
}

/* SOLO TABLET */
@media (max-width: 1129px){
.pulsante_arancio{
    font-size: 14px !important;
}

.header{
    font-size: 12px;
}

.nav>li>a{
    font-size: 12px;
}

.spazio_prenota{
    background-color: #fd3f00;
    padding: 20px 0;
    position: relative;
    bottom: 0;
}

.bg_arancio{
    background-color: #fd3f00;
}


.prezzo_app_hp{
    font-size: 13px;
}

.spazio_intro_home{
    margin-top: 20px;
    margin-bottom: 20px;
}

.lista_guarda{
    margin-top: 20px;
    margin-bottom: 20px;
}

.spazio_info_gite{
    font-size: 14px;
    line-height: 20px;
}

.spazio_info_gite p{
   /* min-height: 60px;*/
}

.info_accesso{
    position: relative;
    left: 0;
    margin-left: 0px;
}

.info_lago{
    position: relative;
    background-color: #1199d0;
    right: 0;
    bottom: 0;
    z-index: -100;
}

.img_lago{
    margin: 0 auto;
}

.info_accesso{
    width: 100%;
}

.logo_vantaggio{
    width: 10%;
}

    
}


/* SOLO TABLET */
@media (max-width: 1021px){

.nav>li>a{
    font-size: 14px;
}

.appartamenti_hp{
    position: relative;
    width:100%;
    right: 0;
    top: 0;
}



}

/* SPAZIO IPAD */
@media (min-width: 992px) {
   
    .lista_guarda_ico:nth-child(4n+1) {
       clear: both;
   }

   .lista_due:nth-child(2n+1) {
       clear: both;
   }
}


@media (min-width: 768px) and (max-width: 991px) {
   .lista_guarda_ico:nth-child(2n+1) {
       clear: both;
   }

   .lista_due:nth-child(2n+1) {
       clear: both;
   }
}


/* SPAZIO IPAD */
@media (max-width: 991px) {
    
.logo_residence{
    position: relative;

}

h1{
    font-size:40px;
}

h2{
    font-size:32px;
}


h3{
    font-size:32px;
}

h4{
    font-size: 27px;
}

.header{
    font-size: 14px;
}

.logo_residence{
    width: 15%;
}

.spazio_menu {
    margin-top: 0px;
}

.select_bandiera{
    float: right;
}

.pulsante_arancio_mobile{
    width: 100%;
}

.pulsante_bianco{
    width: 100%;
}

.servizio_top{
    margin-bottom: 15px;
}

.lista_guarda_ico{
    margin-bottom: 30px;
}

.prezzo_app_hp{
    padding: 9% 15px;
}

.text_center{
    text-align: center;
}

.lista_link_right{
    padding-left: 0;
}

.lista_loghi{
    margin:30px 0;
}

.prenota_footer{
    margin-top: 10px;
}

.contenuti_interne{
    margin: 30px 0;
}

.bg_azz_tab{
    width: auto;
}

.chiavi{
    margin-top: 50px;
    padding-left: 0;
    padding-right: 0;
}


}  




/* SOLO MOBILE */
@media (max-width: 767px) {

.spazio_menu{
   margin-top: 0;
}

.navbar-toggle{
    margin-top: 22px;
    margin-bottom: 15px;
}

.navbar-brand{
    padding: 8px 15px;
}

.banner_top{
    margin-top: 10px;
}

.pulsante_prenota_mobile{
    margin-top: 20px;
}

.prenota_mobile{
    margin-top: 12px;
}

.riga_news{
    border-left: 0;
}

.carousel-indicators{
    margin-right: 0;
    width: 30%;
    text-align: left;
    left: 0;
    display: block;
}

.spazio_info_gite p{
    min-height: auto;
}

.condizioni{
    font-size: 10px;
}

.logo_vantaggio{
    width: 20%;
}

}


/* SOLO MOBILE */
@media (max-width: 568px) {
    .dati_isolino{
        font-size: 13px;
        line-height: 25px;
    }

    .titolo_app{
    min-height: auto;
    }


}

.fancybox-arrow--right {
    right: 40px !important;
}

.fancybox-arrow--left {
    left: 40px !important;
}
.no-link{
    cursor: auto;
    width: auto;
}

@media (max-width: 1200px){
.pulsante_arancio{
    font-size: 14px !important;
}
}